"use strict";
var platform_browser_dynamic_1 = require('@angular/platform-browser-dynamic');
var core_1 = require('@angular/core');
var router_1 = require('@angular/router');
var common_1 = require('@angular/common');
var http_1 = require('@angular/http');
var app_1 = require('../components/app/app');
var click_block_1 = require('../util/click-block');
var config_1 = require('./config');
var events_1 = require('../util/events');
var feature_detect_1 = require('../util/feature-detect');
var form_1 = require('../util/form');
var directives_1 = require('./directives');
var util_1 = require('../util/util');
var keyboard_1 = require('../util/keyboard');
var menu_controller_1 = require('../components/menu/menu-controller');
var dom_1 = require('../util/dom');
var nav_registry_1 = require('../components/nav/nav-registry');
var platform_1 = require('../platform/platform');
var scroll_view_1 = require('../util/scroll-view');
var tap_click_1 = require('../components/tap-click/tap-click');
var translate_1 = require('../translation/translate');
var _reflect = Reflect;
function ionicBootstrap(appRootComponent, customProviders, config) {
    // get all Ionic Providers
    var providers = ionicProviders(customProviders, config);
    // automatically set "ion-app" selector to users root component
    addSelector(appRootComponent, 'ion-app');
    // call angular bootstrap
    return platform_browser_dynamic_1.bootstrap(appRootComponent, providers).then(function (ngComponentRef) {
        // ionic app has finished bootstrapping
        return ionicPostBootstrap(ngComponentRef);
    });
}
exports.ionicBootstrap = ionicBootstrap;
function ionicPostBootstrap(ngComponentRef) {
    var app = ngComponentRef.injector.get(app_1.App);
    app.setAppInjector(ngComponentRef.injector);
    // prepare platform ready
    var platform = ngComponentRef.injector.get(platform_1.Platform);
    platform.setZone(ngComponentRef.injector.get(core_1.NgZone));
    platform.prepareReady();
    // TODO: Use PLATFORM_INITIALIZER
    ngComponentRef.injector.get(tap_click_1.TapClick);
    // TODO: Use Renderer
    ngComponentRef.location.nativeElement.classList.add('app-init');
    return ngComponentRef;
}
exports.ionicPostBootstrap = ionicPostBootstrap;
function ionicProviders(customProviders, config) {
    var directives = directives_1.IONIC_DIRECTIVES;
    // add custom providers to Ionic's app
    customProviders = util_1.isPresent(customProviders) ? customProviders : [];
    // create an instance of Config
    if (!(config instanceof config_1.Config)) {
        config = new config_1.Config(config);
    }
    // enable production mode if config set to true
    if (config.getBoolean('prodMode')) {
        core_1.enableProdMode();
    }
    // create an instance of Platform
    var platform = new platform_1.Platform();
    // initialize platform
    platform.setUrl(window.location.href);
    platform.setUserAgent(window.navigator.userAgent);
    platform.setNavigatorPlatform(window.navigator.platform);
    platform.load(config);
    config.setPlatform(platform);
    var clickBlock = new click_block_1.ClickBlock();
    var events = new events_1.Events();
    var featureDetect = new feature_detect_1.FeatureDetect();
    var navRegistry = new nav_registry_1.NavRegistry();
    setupDom(window, document, config, platform, clickBlock, featureDetect);
    bindEvents(window, document, platform, events);
    return [
        app_1.App,
        core_1.provide(click_block_1.ClickBlock, { useValue: clickBlock }),
        core_1.provide(config_1.Config, { useValue: config }),
        core_1.provide(events_1.Events, { useValue: events }),
        core_1.provide(feature_detect_1.FeatureDetect, { useValue: featureDetect }),
        form_1.Form,
        keyboard_1.Keyboard,
        menu_controller_1.MenuController,
        core_1.provide(nav_registry_1.NavRegistry, { useValue: navRegistry }),
        core_1.provide(platform_1.Platform, { useValue: platform }),
        translate_1.Translate,
        tap_click_1.TapClick,
        core_1.provide(core_1.PLATFORM_DIRECTIVES, { useValue: [directives], multi: true }),
        router_1.ROUTER_PROVIDERS,
        core_1.provide(common_1.LocationStrategy, { useClass: common_1.HashLocationStrategy }),
        http_1.HTTP_PROVIDERS,
        customProviders
    ];
}
exports.ionicProviders = ionicProviders;
function setupDom(window, document, config, platform, clickBlock, featureDetect) {
    var bodyEle = document.body;
    var mode = config.get('mode');
    // if dynamic mode links have been added the fire up the correct one
    var modeLinkAttr = mode + '-href';
    var linkEle = document.head.querySelector('link[' + modeLinkAttr + ']');
    if (linkEle) {
        var href = linkEle.getAttribute(modeLinkAttr);
        linkEle.removeAttribute(modeLinkAttr);
        linkEle.href = href;
    }
    var headStyle = document.createElement('style');
    headStyle.innerHTML = 'ion-app{display:none}';
    document.head.appendChild(headStyle);
    // set the mode class name
    // ios/md/wp
    bodyEle.classList.add(mode);
    // language and direction
    platform.setDir(document.documentElement.dir, false);
    platform.setLang(document.documentElement.lang, false);
    var versions = platform.versions();
    platform.platforms().forEach(function (platformName) {
        // platform-ios
        var platformClass = 'platform-' + platformName;
        bodyEle.classList.add(platformClass);
        var platformVersion = versions[platformName];
        if (platformVersion) {
            // platform-ios9
            platformClass += platformVersion.major;
            bodyEle.classList.add(platformClass);
            // platform-ios9_3
            bodyEle.classList.add(platformClass + '_' + platformVersion.minor);
        }
    });
    // touch devices should not use :hover CSS pseudo
    // enable :hover CSS when the "hoverCSS" setting is not false
    if (config.get('hoverCSS') !== false) {
        bodyEle.classList.add('enable-hover');
    }
    // run feature detection tests
    featureDetect.run(window, document);
}
/**
 * Bind some global events and publish on the 'app' channel
 */
function bindEvents(window, document, platform, events) {
    window.addEventListener('online', function (ev) {
        events.publish('app:online', ev);
    }, false);
    window.addEventListener('offline', function (ev) {
        events.publish('app:offline', ev);
    }, false);
    window.addEventListener('orientationchange', function (ev) {
        events.publish('app:rotated', ev);
    });
    // When that status taps, we respond
    window.addEventListener('statusTap', function (ev) {
        // TODO: Make this more better
        var el = document.elementFromPoint(platform.width() / 2, platform.height() / 2);
        if (!el) {
            return;
        }
        var content = dom_1.closest(el, 'scroll-content');
        if (content) {
            var scroll = new scroll_view_1.ScrollView(content);
            scroll.scrollTo(0, 0, 300);
        }
    });
    // start listening for resizes XXms after the app starts
    dom_1.nativeTimeout(function () {
        window.addEventListener('resize', function () {
            platform.windowResize();
        });
    }, 2000);
}
/**
 * @private
 */
function addSelector(type, selector) {
    if (type) {
        var annotations = _reflect.getMetadata('annotations', type);
        if (annotations && !annotations[0].selector) {
            annotations[0].selector = selector;
            _reflect.defineMetadata('annotations', annotations, type);
        }
    }
}
exports.addSelector = addSelector;
