"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var core_1 = require('@angular/core');
var util_1 = require('../../util/util');
var DISABLE_SCROLL = 'disable-scroll';
/**
 * @private
 */
var Backdrop = (function () {
    function Backdrop(elementRef) {
        this.elementRef = elementRef;
        this.pushed = false;
        this.disableScroll = true;
    }
    Backdrop.push = function () {
        if (this.nuBackDrops === 0) {
            void 0;
            document.body.classList.add(DISABLE_SCROLL);
        }
        else {
            void 0;
        }
        this.nuBackDrops++;
    };
    Backdrop.pop = function () {
        if (this.nuBackDrops === 0) {
            void 0;
            return;
        }
        this.nuBackDrops--;
        if (this.nuBackDrops === 0) {
            void 0;
            document.body.classList.remove(DISABLE_SCROLL);
        }
    };
    Backdrop.prototype.ngOnInit = function () {
        if (util_1.isTrueProperty(this.disableScroll)) {
            Backdrop.push();
            this.pushed = true;
        }
    };
    Backdrop.prototype.ngOnDestroy = function () {
        if (this.pushed) {
            Backdrop.pop();
            this.pushed = false;
        }
    };
    Backdrop.nuBackDrops = 0;
    __decorate([
        core_1.Input(), 
        __metadata('design:type', Object)
    ], Backdrop.prototype, "disableScroll", void 0);
    Backdrop = __decorate([
        core_1.Directive({
            selector: 'ion-backdrop',
            host: {
                'role': 'presentation',
                'tappable': '',
                'disable-activated': ''
            },
        }), 
        __metadata('design:paramtypes', [core_1.ElementRef])
    ], Backdrop);
    return Backdrop;
}());
exports.Backdrop = Backdrop;
